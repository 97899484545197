.touchevents .animate-slide-in {
  opacity: 0;
  transition: opacity 0.05s;
}
.touchevents .animate-slide-in.come-in {
  opacity: 1;
}

.animate-slide-in.come-in {
  -webkit-transform: translateY(80px);
          transform: translateY(80px);
  -webkit-animation: come-in 0.6s ease forwards;
          animation: come-in 0.6s ease forwards;
}

.services-list .service.come-in:nth-child(5n+2) {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
}
.services-list .service.come-in:nth-child(5n+3) {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.services-list .service.come-in:nth-child(5n+4) {
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
}
.services-list .service.come-in:nth-child(5n+5) {
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
}

.animate-slide-in.already-visible {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes come-in {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes come-in {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}