.touchevents .animate-zoom-in {
  opacity: 0;
  transition: opacity 0.05s;
}
.touchevents .animate-zoom-in.come-in {
  opacity: 1;
}

.animate-zoom-in.come-in {
  -webkit-transform: scale(0.3);
          transform: scale(0.3);
  -webkit-animation: come-in 1.2s ease forwards;
          animation: come-in 1.2s ease forwards;
}

.animate-zoom-in.already-visible {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes come-in {
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes come-in {
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}