.touchevents .animate-fade-in {
  opacity: 0;
  transition: opacity 0.05s;
}
.touchevents .animate-fade-in.come-in {
  opacity: 1;
}

.animate-fade-in.come-in {
  -webkit-animation: fade-in 1.5s ease-in-out forwards;
          animation: fade-in 1.5s ease-in-out forwards;
}

.animate-fade-in.already-visible {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes fade-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  80% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  80% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}